// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import './index.scss'
import LogoWide from "../../components/images/logowide"
import ShopifyLogo from "../../components/images/shopify"
import AmazonLogo from "../../components/images/amazon"
import MercadoLibreLogo from "../../components/images/mercadolibre"

type DataProps = {
  site: {
    buildTime: string
  }
}

const EcommerceLanding: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
  <Layout isEcommerce={true} location={location}>
    <SEO title="FiscalPOP para eCommerce" />
    <div id='EcommerceIndex'>

      <div className="title">
        <div className="logos">
          <LogoWide format={'icon'} />
          <LogoWide format={'text'} />
        </div>
        <h1>
          eCommerce
      </h1>
      </div>
      <div className="subtitles">
        <h4>
        Integra nuestra app a tu plataforma de eCommerce
        </h4>
        <h5 className="subt">
        Selecciona la plataforma que necesites para comenzar a disfrutar
          de los beneficios de Fiscalpop para tu comercio en línea.
        </h5>
      </div>
      <div className="integrations">
        <div className="option">
          <Link to="/ecommerce/shopify">
            <ShopifyLogo />
          </Link>
        </div>
        <div className="option">
          <Link to="/ecommerce/mercadolibre">
            <MercadoLibreLogo />
          </Link>
        </div>
        <div className="option">
          <Link to="/ecommerce/amazon">
          <AmazonLogo />
          </Link>
        </div>
      </div>
      <div className="disclaimer">
        <p>
        Las cuentas creadas en nuestras apps y plugins pueden ser independientes de las cuentas Lite, API y Professional por 
        motivos internos de las plataformas de eCommerce, como en el caso de Shopify, donde la app está dentro de tu Admin.
        </p>
        <p className="highlight">
        Revisa el plug-in que deseas para saber más de esta información.
        </p>
      </div>
    </div>
  </Layout>
)

export default EcommerceLanding

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
